import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Gap from "../elements/gap.tsx";
import Text from "../elements/text.tsx";
import ImageText from "../elements/imagetext.tsx";
import Title from "../elements/title.tsx";
import AliceCarousel from "react-alice-carousel";
import Annotation from "../elements/annotation.tsx";
import TextLayout from "../elements/textlayout.tsx";
import PhotoGallery from "../elements/gallery.tsx";
import "react-alice-carousel/lib/alice-carousel.css";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <TextLayout mdxType="TextLayout">
  <h4>SeaSaw</h4>
  <h5>A playful kitchen experience for children</h5>
  <br />
  <br />
  2022 <br />
  Individual
  <br />
  Keywords: controlled risk, embodied movement, children
    </TextLayout>
    <iframe width="100%" height="500" src="https://www.youtube.com/embed/lDKrRnS71Ig?si=MdAT9GuSBz58cmGh" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <Gap margin={30} mdxType="Gap" />
    <PhotoGallery photos={[{
      src: "../../../seasaw/render5.jpeg",
      width: 1454,
      height: 818
    }, {
      src: "../../../seasaw/seasaw.jpeg",
      width: 5417,
      height: 3047
    }, {
      src: "../../../seasaw/seasaw2.jpeg",
      width: 3862,
      height: 2896
    }, {
      src: "../../../seasaw/seasaw4.jpeg",
      width: 2,
      height: 1
    }, {
      src: "../../../seasaw/seasaw3.jpeg",
      width: 3,
      height: 2
    }, {
      src: "../../../seasaw/render3.jpeg",
      width: 1,
      height: 1
    }, {
      src: "../../../seasaw/test5.jpeg",
      width: 4032,
      height: 3024
    }, {
      src: "../../../seasaw/test3.jpeg",
      width: 4032,
      height: 3024
    }, {
      src: "../../../seasaw/test4.jpeg",
      width: 4032,
      height: 3024
    }]} mdxType="PhotoGallery" />
    <TextLayout mdxType="TextLayout">
      <h4>Background</h4>
      <Text mdxType="Text">
  According to an earlier survey I conducted among young adults, among 77
  responses, 3 in 10 people find cooking themselves extremely difficult when
  they first moved out family. And 92.8% people agree that “Being involved in
  the kitchen at a young age would make me loving cooking more” and 59.8%
  participants wish “my family could teach me cooking more when I was young”.
  Unable to cook themselves is a big barrier for young adults to become
  independent.
  <br />
  <br />
  This project focuses on <b>
    "how to encourages children more involved in the kitchen, in order to
    prepare them for future independence"
  </b>
      </Text>
      <Title mdxType="Title">
  <h4>Design Principles</h4>
      </Title>
      <Text title="Controlled Risk: as safe as needed" mdxType="Text">
  If children are presented with risky items with a serious functional purpose,
  they will conduct more cautiously, conduct more experimentation, and gain more
  confidence and independence than overly safe items. We should focus on
  designing for children "as safe as needed" rather than "as safe as possible"
  (Brussoni et al., 2012)
      </Text>
      <Text title="Embodied Movement: dance with food" mdxType="Text">
  This project focuses on engaging children in cooking with their whole body,
  like they are dancing with the food. It also comes from the consideration that
  children don't have the fine motor skills to control a knife yet.
      </Text>
      <Title mdxType="Title">
  <h3>Ideations</h3>
      </Title>
      <img src="../../../seasaw/Sketch2.jpg" />
      <img src="../../../seasaw/Sketch3.png" />
      <img src="../../../seasaw/Sketch1.png" />
      <img src="../../../seasaw/Sketch4.png" />
      <Title mdxType="Title">
  <h3>Design</h3>
      </Title>
      <img src="../../../seasaw/render1.png" />
      <i>Translucent surface for enriched chopping experience</i>
      <Gap margin={20} mdxType="Gap" />
      <img src="../../../seasaw/render5.png" />
      <i>Detachable blade, heavy weight and protection rail for children safety</i>
      <Gap margin={20} mdxType="Gap" />
      <img src="../../../seasaw/render3.png" />
      <i>
  A small size for table usage and a big size for supporting both solo and
  cooperative play
      </i>
      <Gap margin={20} mdxType="Gap" />
      <Title mdxType="Title">
  <h3>Development</h3>
      </Title>
      <AliceCarousel animationDuration="2000" infinite controlsStrategy="alternate" autoPlay mouseTracking disableButtonsControls items={[<img src="../../../seasaw/development1.JPG" onDragStart={e => {
        e.preventDefault();
      }} role="presentation" />, <img src="../../../seasaw/development2.JPG" onDragStart={e => {
        e.preventDefault();
      }} role="presentation" />, <img src="../../../seasaw/development3.JPG" onDragStart={e => {
        e.preventDefault();
      }} role="presentation" />, <img src="../../../seasaw/development4.JPG" onDragStart={e => {
        e.preventDefault();
      }} role="presentation" />]} mdxType="AliceCarousel" />
      <Title mdxType="Title">
  <h3>Testing</h3>
      </Title>
      <Text mdxType="Text">
  I collaborated with
  <a href="https://nicolaikultur.dk/for-born/"> Nicolai for børn</a>, a cultural
  child center in Kolding, for user testing. To help participants better understand
  the session, a poster, a info flyer and consent forms in Danish or English were
  prepared.
  <br /> <br />
  <img src="../../../seasaw/Poster.png" />
  <Annotation mdxType="Annotation">A poster for children</Annotation>
  <img src="../../../seasaw/info-flyer.png" />
  <Annotation mdxType="Annotation">An information flyer for parents</Annotation>
  <img src="../../../seasaw/test1.JPG" />
  <Annotation mdxType="Annotation">The set up for the session</Annotation>
  In total, seven children, from 4 - 8 years old, interacted with SeaSaw. After their
  interaction, I interviewed their parents and got feedbacks for the next iteration.
  <br /> <br />
  <img src="../../../seasaw/test2.JPG" />
      </Text>
    </TextLayout>
    <Gap margin={100} mdxType="Gap" />
    <hr />
    <h5>{`References:`}</h5>
    <div>
  Brussoni, M., Olsen, L. L., Pike, I., & Sleet, D. A. (2012, August 30). Risky
  Play and Children’s Safety: Balancing Priorities for Optimal Child
  Development. International Journal of Environmental Research and Public
  Health, 9(9), 3134–3148. https://doi.org/10.3390/ijerph9093134
    </div>
    <br />
    <div>
  Parten, M. B. (1933). “Social Play among Preschool Children”. Journal of
  Abnormal and Social Psychology. 28 (2): 136–147. doi:10.1037/h0073939
    </div>
    <br />
    <div>
  Frens, J. (2017, October 18). Designing for Embodied and Rich Interaction in
  Home IoT. Proceedings of the Conference on Design and Semantics of Form and
  Movement - Sense and Sensitivity, DeSForM 2017.
  https://doi.org/10.5772/intechopen.71130
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      